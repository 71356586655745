import { twJoin } from '@troon/tailwind-preset/merge';
import { Show } from 'solid-js';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	isCurrent?: boolean;
	floating?: boolean;
}>;

export function ProductCard(props: Props) {
	return (
		<div
			class={twJoin(
				props.floating && 'shadow-md',
				'relative flex size-full flex-col gap-6 rounded border border-neutral bg-white px-6 py-8 sm:px-8',
			)}
		>
			<Show when={props.isCurrent}>
				<div class="absolute inset-x-0 -top-px h-4 rounded border-t-4 border-brand" />
			</Show>
			{props.children}
		</div>
	);
}
