import { createAsync } from '@solidjs/router';
import { For, Match, Show, Suspense, Switch } from 'solid-js';
import {
	ActivityIndicator,
	Button,
	Link,
	ProductCard,
	Tag,
	TroonAccessPlusProduct,
	TroonAccessProduct,
} from '@troon/ui';
import { Icon } from '@troon/icons';
import { getAccessProducts } from '../providers/card';
import { useUser } from '../providers/user';

type Props = {
	floating?: boolean;
	redirect?: string;
};

export function AccessProducts(props: Props) {
	const data = createAsync(() => getAccessProducts({}), { deferStream: true });
	const user = useUser();

	return (
		<Suspense fallback={<ActivityIndicator />}>
			<Show when={data()?.products}>
				{(products) => (
					<ul class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:gap-6">
						<For each={products()}>
							{(card) => (
								<li class="shrink grow">
									<ProductCard
										isCurrent={user()?.activeTroonCardSubscription?.name === card.subscriptionName}
										floating={props.floating}
									>
										<div class="flex flex-col gap-4">
											<div class="flex flex-row items-center justify-between">
												<h2 class="text-xl font-semibold">
													<Show
														when={card.subscriptionName.endsWith('+')}
														fallback={<TroonAccessProduct class="h-8" />}
													>
														<TroonAccessPlusProduct class="h-8" />
													</Show>
													<span class="sr-only">{card.subscriptionName}</span>
												</h2>
												<Show when={user()?.activeTroonCardSubscription?.name === card.subscriptionName}>
													<Tag>Current Plan</Tag>
												</Show>
											</div>
											<Show when={card.description}>
												<p>{card.description}</p>
											</Show>
										</div>
										<p class="mb-2 text-3xl font-semibold">
											<span class="text-5xl">{card.amount.displayValue}</span>/yr
										</p>

										<div class="flex flex-col gap-4 border-t border-neutral pt-8">
											<p>Includes:</p>
											<ul class="flex flex-col gap-3">
												<For each={card.valueProps}>
													{(value) => (
														<li class="flex flex-row items-center gap-2">
															<Icon name="check" class="shrink-0 text-xl text-brand" />
															{value}
														</li>
													)}
												</For>
											</ul>
										</div>
										<div class="flex h-full grow flex-col justify-end">
											<div>
												<Switch>
													<Match when={user()?.activeTroonCardSubscription?.name === card.subscriptionName}>
														<Button as={Link} href="/account/access" appearance="secondary">
															Manage Plan
														</Button>
													</Match>
													<Match when={!user()?.activeTroonCardSubscription}>
														<Button
															as={Link}
															rel="nofollow"
															href={`/access/checkout/${card.id}`}
															state={{ redirect: props.redirect }}
															appearance={card.isPopular ? 'primary' : 'secondary'}
														>
															<span>
																Select<span class="sr-only"> the {card.subscriptionName}</span> and Continue
															</span>{' '}
															<Icon name="arrow-right-md" />
														</Button>
													</Match>
												</Switch>
											</div>
										</div>
									</ProductCard>
								</li>
							)}
						</For>
					</ul>
				)}
			</Show>
		</Suspense>
	);
}
